<template>
  <section class="pay-now ez-view-container">
    <div class="body">
<!--      <span v-if="!paymentDone" class="notice">*{{ $t('account_waiting_for_payment') }}</span>-->
      <div class="form">
        <div class="left">
          <img src="@/assets/illustrations/ez-checkout.png" alt="EZLeads Checkout"/>
        </div>
        <div class="right">
          <div class="thank-you" v-if="paymentDone">
            <i class="ez-icon-done"></i>
            <h1>{{ $t('thank_you-payment') }}</h1>
            <p>{{ $t('thank_you') }}</p>
            <Button @click="logout()" :text="$t('Ok')" type="primary"></Button>
          </div>

          <div v-else class="payment-controls">

            <div class="payment_review">

              <div class="title">
                <h4>{{ owe.toFixed(2) }}</h4>
              </div>
              <div class="review">
                <div class="invoice" v-for="invoice  in unpaidInvoices" v-bind:key="invoice.id">
                  <div class="date">
                    <span class="period">{{ $t('period') }}</span>:
                    <span>{{ getDate(invoice.PeriodStart, 'MMM DD, YYYY') }} - {{ getDate(invoice.PeriodEnd, 'MMM DD, YYYY') }}</span>
                  </div>
                </div>
              </div>

            </div>

            <div class="card">
              <div class="address">
                <input
                    type="text"
                    v-model="card.address_line1"
                    :placeholder="$t('address_placeholder')"
                />
              </div>

              <div class="city-state">
                <div class="city">
                  <input type="text" v-model="card.address_city" :placeholder="$t('city_placeholder')"/>
                </div>

                <div class="state">
                  <select v-model="card.address_state">
                    <option value="" disabled selected>{{ $t('select_state') }}</option>
                    <option v-for="state in states" :value="state.abbreviation" :key="state.abbreviation">{{ state.name }}</option>
                  </select>
                </div>
              </div>

              <div class="zip-country">
                <div class="zip">
                  <input type="text" v-model="card.address_zip" :placeholder="$t('zip_placeholder')"/>
                </div>

                <div class="country">
                  <select v-model="card.address_country">
                    <option disabled>{{ $t('select_country') }}</option>
                    <option value="US">United States</option>
                  </select>
                </div>
              </div>

              <div class="name">
                <input type="text" v-model="card.name" :placeholder="$t('name_placeholder')"/>
              </div>

              <div class="stripe-element">
                <div ref="cardNumber"></div>
                <div ref="cardExpiry"></div>
                <div ref="cardCvc"></div>
              </div>
            </div>

            <div class="footer">
              <Button @click="logout()" height="50px" width="130px" :text="$t('btn_logout')" type="delete"/>

              <Button @click="pay()" height="50px" width="130px" :text="$t('btn_pay')" :disabled="loading" type="primary">
            <span>
              <Spinner v-if="loading"/>
            </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import BillingService from "@/services/billing.service";
import DateHelper from "@/helpers/date.helper";
import config from "@/config";
import States from '@/helpers/states.js'

const public_key = config[config.stage].stripe_key;

const service = new BillingService();


let stripe = undefined,
    elements = undefined,
    cardNumber = undefined,
    cardExpiry = undefined,
    cardCvc = undefined;

/*global Stripe*/
/*eslint no-undef: ["error", { "typeof": true }] */
if (typeof Stripe !== 'undefined') {
  stripe = Stripe(public_key);
  elements = stripe.elements();
}

export default {
  name: "ActivationPayment",
  components: {},
  props: [],
  data() {
    return {
      card: {
        name: "",
        address_line1: "",
        address_city: "",
        address_state: "",
        address_zip: "",
        address_country: "US"
      },
      loading: false,
      paymentDone: false,
      unpaidInvoices: [],
      credits: 0,
      customerId: null,
      states: States,
    };
  },

  async mounted() {
    cardNumber = elements.create("cardNumber", {
      showIcon: true,
      style: this.styles,
      classes: this.classes
    });
    cardCvc = elements.create("cardCvc", {
      style: this.styles,
      classes: this.classes
    });
    cardExpiry = elements.create("cardExpiry", {
      style: this.styles,
      classes: this.classes
    });

    cardNumber.mount(this.$refs.cardNumber);
    cardCvc.mount(this.$refs.cardCvc);
    cardExpiry.mount(this.$refs.cardExpiry);

    const [inv, creds] = await Promise.all([
      service.unpaidInvoices(),
      service.credits()
    ]);

    if (inv && !inv.error) {
      this.unpaidInvoices = inv.data.invoices;
    }

    if (creds && !creds.error) {
      this.credits = creds.data.credit;
      this.customerId = creds.data.customerId;
    }
  },

  computed: {
    owe() {
      return this.unpaidInvoices.reduce((a, b) => a + (b.Amount || 0), 0);
    }
  },

  methods: {
    async pay() {
      try {
        this.loading = true;
        const result = await stripe.createToken(cardNumber);

        const resUpdate = await service.updateCustomer(result.token.id);
        if (!resUpdate || resUpdate.error) {
          //TODO: Handle error
          //TODO: notify error
          this.loading = false;
          return;
        }

        const res = await service.payNow(result.token.id);
        if (!res || res.error) {
          //TODO: Handle error
          //TODO: notify error
          throw new Error(res);
        } else {
          this.paymentDone = true;
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    getDate(dateStr, format) {
      return DateHelper.customFormat(dateStr, format);
    },

    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },

  beforeDestroy() {
    if (cardNumber)
      cardNumber.destroy(this.$refs.cardNumber);
    if (cardCvc)
      cardCvc.destroy(this.$refs.cardCvc);
    if (cardExpiry)
      cardExpiry.destroy(this.$refs.cardExpiry);
  }
};
</script>
<style scoped lang="scss">
.pay-now {
  background-image: -webkit-linear-gradient(140deg, #23026d 0%, #3d64f4 100%);
  background-image: linear-gradient(140deg, #23026d 0%, #3d64f4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
  &::before {
    content: '';
    background: url("~@/assets/illustrations/texture-1.png") no-repeat center / cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }

  .body {
    background: url("~@/assets/illustrations/texture-1.png") no-repeat center / cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    position: relative;
    z-index: 999999;

    .notice {
      border-bottom: 2px solid color(border);
      padding: 20px;
      border-radius: 6px;
      width: 450px;
      margin-bottom: 20px;
      text-align: justify;
      color: color(text-light);
    }

    .form {
      display: grid;
      box-shadow: 0 16px 18px rgba(0,0,0, 0.12);
      grid-template-columns: 1fr 400px;
      align-items: center;
      border-radius: 3px;
      overflow: hidden;
      height: 90vh;
      width: 1200px;
      @include breakpoint-height-down(medium){
        height: 90vh;
      }
      @include breakpoint-height-up(large){
        height: 60vh;
      }

      .left{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        img{
          width: 100%;
        }
        &::before {
          content: '';
          background: url("~@/assets/illustrations/texture-2.png") no-repeat center / cover;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
        }
      }

      .right{
        height: 100%;
        .payment-controls{
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 20px;
          background:color(bg-white);
          border-left:1px solid color(border);

          .payment_review {
            background: rgb(46, 91, 255);
            background-image: -webkit-linear-gradient(140deg, #23026d 0%, #3d64f4 100%);
            border-radius: 6px;
            color: white;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 120px;
            margin: 20px 0 50px;
            position: relative;
            @include breakpoint-height-down(medium){
              margin:20px 0;
            }

            .title {
              font-size: 3.5em;
              text-align: center;
            }

            .review {
              .invoice {
                .date {
                  font-size: 0.8em;
                  text-transform: uppercase;
                  font-weight: $font-bold;
                  color: rgba(white, 0.8);
                }
              }
            }

            i {
              color: rgba(42, 79, 212, 0.8);
              font-size: 6em;
              position: absolute;
              right: 12px;
            }
          }

          .card {
            flex:1;
            & > div {
              input,
              select {
                box-sizing: border-box;
                width: 100%;
                height: 50px;
                padding: 10px 12px;
                border: 1px solid color(border);
                font-size: 1.1em;
                border-radius: 4px;
                background-color: white;
                transition: box-shadow 150ms ease;
                margin-bottom: 20px;
              }
            }

            .city-state,
            .zip-country {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 20px;
            }

            .name {
              margin-top: 30px;
            }


            .stripe-element {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 20px;
              & > div{
                &:first-child{
                  grid-column: span 2;
                }
              }
            }

            .StripeElement {
              background:color(bg-white);
              box-sizing: border-box;
              height: 50px;
              font-size: 1.1em;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 0 20px;
              margin-bottom: 8px;
              border: 1px solid color(border);
              border-radius: 3px;
              transition: box-shadow 150ms ease;
            }

            .StripeElement--focus {
              box-shadow: 0 1px 3px 0 #cfd7df;
            }

            .StripeElement--invalid {
              border-color: #fa755a;
            }

            .StripeElement--webkit-autofill {
              background-color: #fefde5 !important;
            }
          }

          .footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 10px;
            height: 80px;

            div {
              &:last-child {
                margin-left: 10px;
              }
            }
          }
        }
        .thank-you{
          background: color(bg-white);
          height: 100%;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap:20px;
          align-items: center;
          i {
            border-radius: 50%;
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: color(bg-green);
            font-size: 5em;
            color: white;
          }

          h1{
            color:color(bg-blue-dark);
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: $font-bold;
            text-align: center;
          }

          p{
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
<i18n scoped>
{
"en": {
"account_waiting_for_payment": "Our records indicate that we have not received payment for invoice(s) on your account. As such, in accordance with our Terms of Services contract, your service(s) remain inactive until new payment is registered.",
"checkout_title": "EZLeads Checkout",
"period": "Period",
"address_placeholder": "Billing Address",
"city_placeholder": "City",
"select_state": "Select State",
"zip_placeholder": "Zip",
"select_country": "Select Country",
"name_placeholder": "Cardholder Name",
"btn_cancel": "Cancel",
"btn_logout": "Logout",
"btn_pay": "Pay",
"thank_you": "You will receive payment status notifications via email. If you have any inconvenient please get in touch with sales team at sales@ezleads.io.",
"thank_you-payment": "Thank you for your payment.",
"back": "Back to "
},
"es": {
"account_waiting_for_payment": "Nuestros registros indican que no se ha recibido el pago correspondiente a alguna(s) factura(s) en su cuenta.De acuerdo a su contrato con nuestros Términos de Servicio, su permanece inactivo hasta que se registre un pago.",
"checkout_title": "EZLeads Pagos",
"period": "Período",
"address_placeholder": "Dirección de facturación",
"city_placeholder": "Ciudad",
"select_state": "Seleccione Estado o Provincia",
"zip_placeholder": "C.P",
"select_country": "Selecione País",
"name_placeholder": "Nombre en la Tarjeta",
"btn_cancel": "Cancelar",
"btn_logout": "Salir",
"btn_pay": "Pagar",
"thank_you": "Recibirá notificacion acerca del estado del mismo vía email. Si tiene algun incoveniente por favor ponerse en contacto con el equipo de ventas en sales @ezleads.io.",
"thank_you-payment": "Gracias por su pago",
"back": "Back to "
}
}
</i18n>
